<!-- eslint-disable max-len -->
<template>
  <svg
    @click="$emit('click')"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.85 7.79632H20.15C20.3754 7.79632 20.5916 7.70601 20.751 7.54527C20.9104 7.38452 21 7.1665 21 6.93917C21 6.71185 20.9104 6.49383 20.751 6.33308C20.5916 6.17234 20.3754 6.08203 20.15 6.08203H4.85C4.62457 6.08203 4.40837 6.17234 4.24896 6.33308C4.08955 6.49383 4 6.71185 4 6.93917C4 7.1665 4.08955 7.38452 4.24896 7.54527C4.40837 7.70601 4.62457 7.79632 4.85 7.79632ZM4.85 11.2249H16.75C16.9754 11.2249 17.1916 11.1346 17.351 10.9738C17.5104 10.8131 17.6 10.5951 17.6 10.3677C17.6 10.1404 17.5104 9.9224 17.351 9.76165C17.1916 9.60091 16.9754 9.5106 16.75 9.5106H4.85C4.62457 9.5106 4.40837 9.60091 4.24896 9.76165C4.08955 9.9224 4 10.1404 4 10.3677C4 10.5951 4.08955 10.8131 4.24896 10.9738C4.40837 11.1346 4.62457 11.2249 4.85 11.2249ZM20.15 12.9392H4.85C4.62457 12.9392 4.40837 13.0295 4.24896 13.1902C4.08955 13.351 4 13.569 4 13.7963C4 14.0236 4.08955 14.2417 4.24896 14.4024C4.40837 14.5632 4.62457 14.6535 4.85 14.6535H20.15C20.3754 14.6535 20.5916 14.5632 20.751 14.4024C20.9104 14.2417 21 14.0236 21 13.7963C21 13.569 20.9104 13.351 20.751 13.1902C20.5916 13.0295 20.3754 12.9392 20.15 12.9392ZM16.75 16.3677H4.85C4.62457 16.3677 4.40837 16.4581 4.24896 16.6188C4.08955 16.7795 4 16.9976 4 17.2249C4 17.4522 4.08955 17.6702 4.24896 17.831C4.40837 17.9917 4.62457 18.082 4.85 18.082H16.75C16.9754 18.082 17.1916 17.9917 17.351 17.831C17.5104 17.6702 17.6 17.4522 17.6 17.2249C17.6 16.9976 17.5104 16.7795 17.351 16.6188C17.1916 16.4581 16.9754 16.3677 16.75 16.3677Z"
      fill="#F2F2F2"
    />
  </svg>
</template>

<script>
export default {
  name: 'TextAlignLeft',
};
</script>
