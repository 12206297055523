<!-- eslint-disable max-len -->
<template>
  <svg
    @click="$emit('click')"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.43398 7.79632H19.734C19.9594 7.79632 20.1756 7.70601 20.335 7.54527C20.4944 7.38452 20.584 7.1665 20.584 6.93917C20.584 6.71185 20.4944 6.49383 20.335 6.33308C20.1756 6.17234 19.9594 6.08203 19.734 6.08203H4.43398C4.20855 6.08203 3.99235 6.17234 3.83294 6.33308C3.67354 6.49383 3.58398 6.71185 3.58398 6.93917C3.58398 7.1665 3.67354 7.38452 3.83294 7.54527C3.99235 7.70601 4.20855 7.79632 4.43398 7.79632ZM19.734 16.3677H4.43398C4.20855 16.3677 3.99235 16.4581 3.83294 16.6188C3.67354 16.7795 3.58398 16.9976 3.58398 17.2249C3.58398 17.4522 3.67354 17.6702 3.83294 17.831C3.99235 17.9917 4.20855 18.082 4.43398 18.082H19.734C19.9594 18.082 20.1756 17.9917 20.335 17.831C20.4944 17.6702 20.584 17.4522 20.584 17.2249C20.584 16.9976 20.4944 16.7795 20.335 16.6188C20.1756 16.4581 19.9594 16.3677 19.734 16.3677ZM19.734 12.9392H4.43398C4.20855 12.9392 3.99235 13.0295 3.83294 13.1902C3.67354 13.351 3.58398 13.569 3.58398 13.7963C3.58398 14.0236 3.67354 14.2417 3.83294 14.4024C3.99235 14.5632 4.20855 14.6535 4.43398 14.6535H19.734C19.9594 14.6535 20.1756 14.5632 20.335 14.4024C20.4944 14.2417 20.584 14.0236 20.584 13.7963C20.584 13.569 20.4944 13.351 20.335 13.1902C20.1756 13.0295 19.9594 12.9392 19.734 12.9392ZM19.734 9.5106H4.43398C4.20855 9.5106 3.99235 9.60091 3.83294 9.76165C3.67354 9.9224 3.58398 10.1404 3.58398 10.3677C3.58398 10.5951 3.67354 10.8131 3.83294 10.9738C3.99235 11.1346 4.20855 11.2249 4.43398 11.2249H19.734C19.9594 11.2249 20.1756 11.1346 20.335 10.9738C20.4944 10.8131 20.584 10.5951 20.584 10.3677C20.584 10.1404 20.4944 9.9224 20.335 9.76165C20.1756 9.60091 19.9594 9.5106 19.734 9.5106Z"
      fill="#F2F2F2"
    />
  </svg>
</template>

<script>
export default {
  name: 'TextAlignCenter',
};
</script>
