<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M5.5 13L19.5 13C19.7761 13 20 13.1492 20 13.3333L20 16.6667C20 16.8508 19.7761 17 19.5 17L5.5 17C5.22386 17 5 16.8508 5 16.6667L5 13.3333C5 13.1492 5.22386 13 5.5 13Z"
      stroke="#F2F2F2"
    />
    <path
      d="M8.5 7L16.5 7C16.7761 7 17 7.14924 17 7.33333L17 10.6667C17 10.8508 16.7761 11 16.5 11L8.5 11C8.22386 11 8 10.8508 8 10.6667L8 7.33333C8 7.14924 8.22386 7 8.5 7Z"
      stroke="#F2F2F2"
    />
    <path d="M12.5 7L12.5 4" stroke="#F2F2F2" stroke-linecap="round" />
    <path d="M12.5 13L12.5 11" stroke="#F2F2F2" />
    <path d="M12.5 20L12.5 17" stroke="#F2F2F2" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'CenterVerticalAlignmentIcon',
};
</script>
