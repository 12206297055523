<!-- eslint-disable max-len -->
<template>
  <svg
    @click="$emit('click')"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.01602 7.79632H19.316C19.5415 7.79632 19.7577 7.70601 19.9171 7.54527C20.0765 7.38452 20.166 7.1665 20.166 6.93917C20.166 6.71185 20.0765 6.49383 19.9171 6.33308C19.7577 6.17234 19.5415 6.08203 19.316 6.08203H4.01602C3.79058 6.08203 3.57438 6.17234 3.41497 6.33308C3.25557 6.49383 3.16602 6.71185 3.16602 6.93917C3.16602 7.1665 3.25557 7.38452 3.41497 7.54527C3.57438 7.70601 3.79058 7.79632 4.01602 7.79632ZM19.316 16.3677H7.41602C7.19058 16.3677 6.97438 16.4581 6.81498 16.6188C6.65557 16.7795 6.56602 16.9976 6.56602 17.2249C6.56602 17.4522 6.65557 17.6702 6.81498 17.831C6.97438 17.9917 7.19058 18.082 7.41602 18.082H19.316C19.5415 18.082 19.7577 17.9917 19.9171 17.831C20.0765 17.6702 20.166 17.4522 20.166 17.2249C20.166 16.9976 20.0765 16.7795 19.9171 16.6188C19.7577 16.4581 19.5415 16.3677 19.316 16.3677ZM19.316 9.5106H7.41602C7.19058 9.5106 6.97438 9.60091 6.81498 9.76165C6.65557 9.9224 6.56602 10.1404 6.56602 10.3677C6.56602 10.5951 6.65557 10.8131 6.81498 10.9738C6.97438 11.1346 7.19058 11.2249 7.41602 11.2249H19.316C19.5415 11.2249 19.7577 11.1346 19.9171 10.9738C20.0765 10.8131 20.166 10.5951 20.166 10.3677C20.166 10.1404 20.0765 9.9224 19.9171 9.76165C19.7577 9.60091 19.5415 9.5106 19.316 9.5106ZM19.316 12.9392H4.01602C3.79058 12.9392 3.57438 13.0295 3.41497 13.1902C3.25557 13.351 3.16602 13.569 3.16602 13.7963C3.16602 14.0236 3.25557 14.2417 3.41497 14.4024C3.57438 14.5632 3.79058 14.6535 4.01602 14.6535H19.316C19.5415 14.6535 19.7577 14.5632 19.9171 14.4024C20.0765 14.2417 20.166 14.0236 20.166 13.7963C20.166 13.569 20.0765 13.351 19.9171 13.1902C19.7577 13.0295 19.5415 12.9392 19.316 12.9392Z"
      fill="#F2F2F2"
    />
  </svg>
</template>

<script>
export default {
  name: 'TextAlignRight',
};
</script>
