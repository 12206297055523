<template>
  <div class='text-style-settings'>
    <div class='sidebar__title'>
      <p>{{ $t('label.textStyle') }}</p>
    </div>
    <div class='text-style-settings__font-family'>
      <b-form-select
        :disabled='disabled'
        v-model='fontFamily'
        :style='computedFontStyle(selectedFontStyle)'
        class='scroll'
        @click.native='finishTutorial'
      >
        <b-form-select-option
          v-for='f in optionsFontFamily'
          :value='f'
          :key='f'
          :style='{ fontFamily: f }'
        >{{ f }}
        </b-form-select-option>
      </b-form-select>
    </div>
    <div class='text-style-settings__font-style'>
      <b-form-select
        :disabled='disabled'
        v-model='fontStyle'
        :style='computedFontStyle(selectedFontStyle)'
        @click.native='finishTutorial'
      >
        <b-form-select-option
          v-for='option in optionsFontStyle'
          :value='option'
          :style='computedFontStyle(option)'
          :key='option'
        >
          {{ option }}
        </b-form-select-option>
      </b-form-select>
    </div>
    <div class='text-style-settings__font-size'>
      <b-select v-model='fontSize' :options='optionsFontSize' class='scroll' :disabled='disabled'
                @click.native='finishTutorial' />
    </div>
    <div class='text-style-settings__alignment'>
      <!-- eslint-disable max-len -->
      <TextAlignLeft
        :key="textAlignment('left')"
        :class="{ active: textAlignment('left') }"
        @click="setAlign('left')"
      />
      <TextAlignCenter :class="{ active: textAlignment('center') }" @click="setAlign('center')" />
      <TextAlignRight :class="{ active: textAlignment('right') }" @click="setAlign('right')" />
    </div>

    <div class='sidebar__title'>
      <p>{{ $t('label.textColor') }}</p>
    </div>
    <div class='text-style-settings__color w-50'>
      <div class='setting-color'>
        <ColorPicker
          ref='colorPicker'
          class='setting-color__color-picker'
          :class="{ 'setting-color__color-picker--visible': showColorPicker }"
          :value='fontColor'
          @input='updateColor'
          @close='showColorPicker = false'
          @click.native='finishTutorial'
        />
        <div ref='colorWrapper' class='setting-color__wrapper' @click='toggleColorPicker'>
          <div class='setting-color__color' :style='`background:${fontColor}`' />
          <div class='setting-color__label'>{{ fontColor }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import range from 'lodash/range';
import WebFont from 'webfontloader';
import Vue from 'vue';
import { abc, fontFamilies } from '@/utils/constants';
import ColorPicker from '@/components/Teacher/ColorPicker/ColorPicker.vue';
import TextAlignLeft from '@/components/Icons/Alignment/TextAlignLeft.vue';
import TextAlignCenter from '@/components/Icons/Alignment/TextAlignCenter.vue';
import TextAlignRight from '@/components/Icons/Alignment/TextAlignRight.vue';

export default {
  name: 'CertificateTextSetting',
  components: {
    TextAlignRight,
    TextAlignCenter,
    TextAlignLeft,
    ColorPicker,
  },
  props: {
    text: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    setDefaultTextSetting: {
      type: Function,
      required: true,
    },
    handleTutorial: {
      type: Function,
      required: true,
    },
    tutorial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    optionsFontFamily: fontFamilies,
    selectedFontFamily: '',

    selectedFontStyle: 'Regular',
    optionsFontStyle: ['Regular', 'Italic', 'Bold', 'Italic Bold'],

    optionsFontSize: range(6, 94),
    selectedColor: '#000000',

    showColorPicker: false,
  }),

  created() {
    fontFamilies.map((f) => {
      WebFont.load({
        google: {
          families: [f],
          text: f,
        },
      });
      return f;
    });
  },

  computed: {
    fontFamily: {
      get() {
        if (this.text?.attrs?.fontFamily) {
          return this.text.attrs.fontFamily;
        }
        return '';
      },
      set(value) {
        this.selectedFontFamily = value;
        this.setDefaultTextSetting({ fontFamily: value });
        if (this.text) {
          WebFont.load({
            google: {
              families: [value],
              text: abc,
            },
            active: () => {
              this.text.fontFamily(value);
              Vue.set(this.text, 'attrs', { ...this.text.attrs, fontFamily: value });
            },
          });
        }
      },
    },
    fontStyle: {
      get() {
        if (this.text?.attrs) {
          if (this.text.attrs.fontStyle === 'normal') {
            return 'Regular';
          }
          if (this.text.attrs.fontStyle) {
            return this.text.attrs.fontStyle;
          }
          if (this.text.attrs.fontStyle === undefined) {
            return 'Regular';
          }
        }
        return '';
      },
      set(value) {
        this.selectedFontStyle = value;
        this.setDefaultTextSetting({ fontStyle: value });
        if (!this.text) {
          return;
        }
        if (value === 'Regular') {
          return this.text.fontStyle('normal');
        }
        if (this.text) {
          this.text.fontStyle(value);
        }
      },
    },
    fontSize: {
      get() {
        if (this.text?.attrs?.fontSize) {
          return this.text.attrs.fontSize;
        }
        return '';
      },
      set(value) {
        this.setDefaultTextSetting({ fontSize: value });
        if (this.text) {
          this.text.fontSize(+value);
        }
      },
    },
    fontColor: {
      get() {
        if (this.text?.attrs?.fill) {
          return this.text.attrs.fill;
        }
        return this.selectedColor;
      },
      set(value) {
        this.setDefaultTextSetting({ fill: value });
        if (this.text) {
          this.text.fill(value);
        }
      },
    },
    textAlignment() {
      return (align) => this.text?.attrs?.align === align;
    },
  },

  methods: {
    computedFontStyle(option) {
      if (option === 'Regular') {
        return 'font-style: normal;';
      }
      if (option === 'Italic') {
        return 'font-style: italic;';
      }
      if (option === 'Bold') {
        return 'font-weight: bold; font-style: normal;';
      }
      if (option === 'Italic Bold') {
        return 'font-weight: bold; font-style: italic;';
      }
      return '';
    },
    updateColor(e) {
      this.selectedColor = e.hex8;
      this.fontColor = e.hex8;
    },
    setAlign(align) {
      if (this.text) {
        this.text.align(align);
        Vue.set(this.text, 'attrs', { ...this.text.attrs, align });
      }
    },
    toggleColorPicker() {
      if (this.disabled) {
        return;
      }
      this.showColorPicker = !this.showColorPicker;
    },

    finishTutorial() {
      if (!this.tutorial.addText) {
        this.handleTutorial('skip');
      }
    },
  },

  watch: {
    fontColor(v) {
      if (v) {
        this.selectedColor = v;
      }
    },
  },
};
</script>
