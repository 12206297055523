<template>
  <div class="onboarding-wrapper">
    <div class="onboarding" :class="{ big: variant === 'big' }">
      <div class="onboarding__arrow" />
      <div class="onboarding__inner-container">
        <div class="onboarding__title">
          <p>
            <slot name="title" />
          </p>
        </div>
        <div v-if="variant === 'big'" class="onboarding__text">
          <p>
            <slot name="text" />
          </p>
        </div>
        <div v-else class="onboarding__label">
          <p>
            <slot name="label" />
          </p>
        </div>
      </div>
      <div class="onboarding__actions" :class="{ 'justify-content-center': variant !== 'big' }">
        <div class="dismiss" @click="$emit('tutorial', 'skip')">
          {{ leftBtnLabel }}
        </div>
        <div v-if="variant === 'big'" class="start" @click="$emit('tutorial', tutorialStep)">
          {{ rightBtnLabel }}
          <b-icon icon="arrow-right" aria-hidden="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingCard',
  props: {
    variant: {
      type: String,
      default: '',
    },
    tutorialStep: {
      type: String,
      default: '',
    },
    leftButtonLabel: {
      type: String,
      default: null,
    },
    rightButtonLabel: {
      type: String,
      default: null,
    },
  },
  computed: {
    leftBtnLabel() {
      if (!this.leftButtonLabel) {
        return this.$i18n.t('buttonLabels.skipHints');
      }
      return this.leftButtonLabel;
    },
    rightBtnLabel() {
      if (!this.rightButtonLabel) {
        return this.$i18n.t('buttonLabels.start');
      }
      return this.rightButtonLabel;
    },
  },
};
</script>
