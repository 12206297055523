<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M11 5.5L11 19.5C11 19.7761 10.8508 20 10.6667 20L7.33333 20C7.14924 20 7 19.7761 7 19.5L7 5.5C7 5.22386 7.14924 5 7.33333 5L10.6667 5C10.8508 5 11 5.22386 11 5.5Z"
      stroke="#F2F2F2"
    />
    <path
      d="M17 8.5L17 16.5C17 16.7761 16.8508 17 16.6667 17L13.3333 17C13.1492 17 13 16.7761 13 16.5L13 8.5C13 8.22386 13.1492 8 13.3333 8L16.6667 8C16.8508 8 17 8.22386 17 8.5Z"
      stroke="#F2F2F2"
    />
    <path d="M17 12.5H20" stroke="#F2F2F2" stroke-linecap="round" />
    <path d="M11 12.5H13" stroke="#F2F2F2" />
    <path d="M4 12.5H7" stroke="#F2F2F2" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'CenterHorizontalAlignmentIcon',
};
</script>
